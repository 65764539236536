import React from "react";

import { FieldSelect, FieldTextInput } from "../../../../../../components";
import { required } from '../../../../../../util/validators';

import ListingAttributesFields from "../../components/ListingAttributesFields/ListingAttributesFields";

import { OTHER } from '../../EditListingDetailsPanel';

import css from "./ListingSubCategoriesFields.module.css";

const ListingSubCategoriesFields = ({
  formId,
  intl,
  subCategories,
  attributes,
  values,
 }) => {

  const subCategoryRequiredMsg =
    intl.formatMessage({ id: "EditListingDetailsForm.subCategoryRequiredMsg" });

  return (
    <>
      {subCategories ? <FieldSelect
        id={`${formId}.subCategory`}
        className={css.selectField}
        name="subCategory"
        label={intl.formatMessage({ id: 'EditListingDetailsForm.subCategoryLabel' })}
        validate={required(subCategoryRequiredMsg)}
      >
        <option disabled value="">
          {intl.formatMessage({ id: 'EditListingDetailsForm.subCategoryPlaceHolder' })}
        </option>
        {subCategories.map( category=> (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </FieldSelect> : null}

      {values?.subCategory?.toLowerCase() === OTHER
        ? (
          <FieldTextInput
            id={`${formId}subCategoryOther`}
            name="subCategoryOther"
            className={css.selectField}
            type="text"
            label={intl.formatMessage({ id: 'EditListingDetailsForm.subCategoryLabelOther' })}
            placeholder={intl.formatMessage({ id: 'EditListingDetailsForm.subCategoryPlaceHolderOther' })}
            validate={required(subCategoryRequiredMsg)}
          />
        ) : null}

      {attributes && values.masterCategory ? <ListingAttributesFields
        attributes={attributes}
        formId={formId}
        values={values}
        intl={intl}
      /> : null}
    </>
  );
}

export default ListingSubCategoriesFields;
