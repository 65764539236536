import React from 'react';
import { FieldArray } from 'react-final-form-arrays';

import { required } from "../../../../../../util/validators";
import {
  FieldTextInput,
  FieldSelect,
  Button,
  IconDelete,
  FieldMultiSelect,
} from "../../../../../../components";

import css from "./ListingAttributesFields.module.css";

const STRING_FIELD_TYPE = "string";
const NUMBER_FIELD_TYPE = "number";
const SELECT_FIELD_TYPE = "select";

const getSelectedAttributesName = (attributes, value) =>
  !!attributes.find(({ attribute }) => attribute.value === value);

const formatInputValue = value => (!!value ? value.replace(/[^\d.]/g, '') : '');

const ListingAttributesFields = ({ formId, attributes, intl, values }) => {

  const attributesOptions = attributes.map(({ label, value, measurement }) =>
    !value.includes("FreeInput")
      ? ({ value, label: `${label}${measurement ? ", " + measurement : ""}`})
      : null
  ).filter(Boolean);

  return (
    <FieldArray name="attributes">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const selectedAttribute = values.attributes[index];
            const attribute = attributes.find(({ value }) =>
              value === selectedAttribute?.attribute?.value
            );
            const freeInputField = attributes.find(({ value }) =>
              value.includes("FreeInput") &&
              attribute?.value === value.replace("FreeInput", "")
            );
            const attr = `${name}.attribute`;

            let field = null;

            if (selectedAttribute && attribute) {
              const {
                value,
                values,
                label,
                measurement,
                required: attrRequired,
                placeholder,
                type,
              } = attribute;

              const id = `${formId}${value}-${index}`;
              const attributeFieldName = `${name}.${value}`;
              const fieldLabel = `${label}${measurement ? ", " + measurement : ""}`;
              const validate = attrRequired ? required(`${label} is required`) : () => undefined;

              switch(type){
                case STRING_FIELD_TYPE:
                  field = (
                    <FieldTextInput
                      key={id}
                      id={id}
                      name={attributeFieldName}
                      type="text"
                      label={fieldLabel}
                      placeholder={placeholder}
                      validate={validate}
                    />
                  );
                  break;

                case NUMBER_FIELD_TYPE:
                  field = (
                    <FieldTextInput
                      format={formatInputValue}
                      parse={formatInputValue}
                      key={id}
                      id={id}
                      name={attributeFieldName}
                      type="text"
                      label={fieldLabel}
                      placeholder={placeholder}
                      validate={validate}
                    />
                  );
                  break;

                case SELECT_FIELD_TYPE:
                  field = (
                    <FieldSelect
                      key={id}
                      id={id}
                      name={attributeFieldName}
                      label={fieldLabel}
                      validate={validate}
                    >
                      <option disabled value="">
                        {placeholder}
                      </option>
                      {values.map(v=> (
                        <option key={v} value={v}>
                          {v}
                        </option>
                      ))}
                    </FieldSelect>
                  );
                  break;
              }
            }

            let freeInput = null;

            if (freeInputField) {
              const {
                value,
                label,
                measurement,
                required: attrRequired,
                placeholder,
              } = freeInputField

              const id = `${formId}${value}-${index}`;
              const attributeFieldName = `${name}.${value}`;
              const fieldLabel = `${label}${measurement ? ", " + measurement : ""}`;
              const validate = attrRequired ? required(`${label} is required`) : () => undefined;

              freeInput = (
                <FieldTextInput
                  className={css.freeInputField}
                  key={id}
                  id={id}
                  name={attributeFieldName}
                  type="text"
                  label={fieldLabel}
                  placeholder={placeholder}
                  validate={validate}
                />
              )
            }

            return (
              <div key={name} className={css.fieldWrapper}>
                <div className={css.inputFields}>
                  <FieldMultiSelect
                    className={css.fieldSelect}
                    key={attr}
                    name={attr}
                    options={attributesOptions}
                    placeholder={intl.formatMessage({ id: 'EditListingDetailsForm.attributePlaceholder' })}
                    label={intl.formatMessage({ id: 'EditListingDetailsForm.attributeLabel' })}
                    id={attr}
                    isMulti={false}
                    isOptionDisabled={(option) => getSelectedAttributesName(values.attributes, option.value)}
                  />

                  <div className={css.field}>
                    {field}
                    {freeInput}
                  </div>
                </div>

                <div className={css.removeBtnWrapper} onClick={() => fields.remove(index)}>
                  <IconDelete className={css.removeIcon} />
                </div>
              </div>
            )}
          )}

          <Button
            className={css.addAttributesBtn}
            type="button"
            onClick={() => fields.push({ attribute: {} })}
          >
            {intl.formatMessage({ id: 'EditListingDetailsForm.addAttribute' })}
          </Button>
        </div>
      )}
    </FieldArray>
  )
}

export default ListingAttributesFields;

