import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { maxLength, required, composeValidators } from '../../../../util/validators';
import { types as sdkTypes } from '../../../../util/sdkLoader';
import { formatMoney } from '../../../../util/currency';

// Import shared components
import { Form, Button, FieldSelect, FieldTextInput, Heading } from '../../../../components';
// Import modules from this directory
// import CustomExtendedDataField from '../CustomExtendedDataField';
import ListingSubCategoriesFields from './components/ListingSubCategoriesFields/ListingSubCategoriesFields';

import {
  OTHER,
  OTHER_FREE_INPUT,
  extendCategoriesAttributes,
} from './EditListingDetailsPanel';

import css from './EditListingDetailsForm.module.css';

const { Money } = sdkTypes;

const TITLE_MAX_LENGTH = 60;

const excludePerLiterCharge = ['cranes', 'power tools', 'skips', 'air compressors', 'cabling', 'roofing', 'plumbing', 'lighting', 'scaffolding'];
const activePerLiterCharge = ['diesel', 'petrol', 'hybrid', 'propane lpg'];

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="EditListingDetailsForm.updateFailed" />
  ) : createListingDraftError ? (
    <FormattedMessage id="EditListingDetailsForm.createListingDraftError" />
  ) : showListingsError ? (
    <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

// Hidden input field
const FieldHidden = props => {
  const { name } = props;
  return (
    <Field id={name} name={name} type="hidden" className={css.unitTypeHidden}>
      {fieldRenderProps => <input {...fieldRenderProps?.input} />}
    </Field>
  );
};

// Field component that either allows selecting listing type (if multiple types are available)
// or just renders hidden fields:
// - listingType              Set of predefined configurations for each listing type
// - transactionProcessAlias  Initiate correct transaction against Marketplace API
// - unitType                 Main use case: pricing unit
const FieldSelectListingType = props => {
  const { name, listingTypes, hasExistingListingType, onListingTypeChange, formApi, intl } = props;
  const hasMultipleListingTypes = listingTypes?.length > 1;

  const handleOnChange = value => {
    const selectedListingType = listingTypes.find(config => config.listingType === value);
    formApi.change('transactionProcessAlias', selectedListingType.transactionProcessAlias);
    formApi.change('unitType', selectedListingType.unitType);

    if (onListingTypeChange) {
      onListingTypeChange(selectedListingType);
    }
  };

  const listingTypeValue = formApi.getFieldState(name)?.value;
  const listingTypeLabel = listingTypes.find(({ listingType }) => listingType === listingTypeValue)?.label ?? "";

  return hasMultipleListingTypes && !hasExistingListingType ? (
    <>
      <FieldSelect
        id={name}
        name={name}
        className={css.listingTypeSelect}
        label={intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeLabel' })}
        validate={required(
          intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeRequired' })
        )}
        onChange={handleOnChange}
      >
        <option disabled value="">
          {intl.formatMessage({ id: 'EditListingDetailsForm.listingTypePlaceholder' })}
        </option>
        {listingTypes.map(config => {
          const type = config.listingType;
          return (
            <option key={type} value={type}>
              {config.label}
            </option>
          );
        })}
      </FieldSelect>
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </>
  ) : hasMultipleListingTypes && hasExistingListingType ? (
    <div className={css.listingTypeSelect}>
      <Heading as="h5" rootClassName={css.selectedLabel}>
        {intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeLabel' })}
      </Heading>
      <p className={css.selectedValue}>{listingTypeLabel}</p>
      <FieldHidden name={name} />
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </div>
  ) : (
    <>
      <FieldHidden name={name} />
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </>
  );
};

// Add collect data for listing fields (both publicData and privateData) based on configuration
// const AddListingFields = props => {
//   const { listingType, listingFieldsConfig, intl } = props;
//   const fields = listingFieldsConfig.reduce((pickedFields, fieldConfig) => {
//     const { key, includeForListingTypes, schemaType, scope } = fieldConfig || {};
//     const namespacedKey = scope === 'public' ? `pub_${key}` : `priv_${key}`;

//     const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
//     const isTargetProcessAlias =
//       includeForListingTypes == null || includeForListingTypes.includes(listingType);
//     const isProviderScope = ['public', 'private'].includes(scope);

//     return isKnownSchemaType && isTargetProcessAlias && isProviderScope
//       ? [
//           ...pickedFields,
//           <CustomExtendedDataField
//             key={namespacedKey}
//             name={namespacedKey}
//             fieldConfig={fieldConfig}
//             defaultRequiredMessage={intl.formatMessage({
//               id: 'EditListingDetailsForm.defaultRequiredMessage',
//             })}
//           />,
//         ]
//       : pickedFields;
//   }, []);

//   return <>{fields}</>;
// };

const formatInputValue = value => (!!value ? value.replace(/[^\d.]/g, '') : '');

// Form that asks title, description, transaction process and unit type for pricing
// In addition, it asks about custom fields according to marketplace-custom-config.js
const EditListingDetailsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        autoFocus,
        className,
        disabled,
        ready,
        formId,
        form: formApi,
        handleSubmit,
        onListingTypeChange,
        intl,
        invalid,
        pristine,
        selectableListingTypes,
        hasExistingListingType,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        // listingFieldsConfig,
        values,
        listingConfig,
        form,
        currency,
      } = formRenderProps;

      const { listingType, masterCategory, unitType } = values;
      const isHire = unitType === "day";

      const isActivePerLiterCharge = values?.attributes?.find(({attribute, fuelType_powerSource}) =>
        fuelType_powerSource &&
        activePerLiterCharge.includes(fuelType_powerSource.toLowerCase()) &&
        attribute.value === "fuelType_powerSource"
      );

      const listingMasterCategories = listingConfig.listingMasterCategories;
      const listingSubCategoriesKey = masterCategory
        ? Object.keys(listingConfig)
          .find(
            (key) =>
              key.toLowerCase().indexOf(masterCategory.toLowerCase()) >= 0 &&
              key.toLowerCase().indexOf("subcategories") >= 0
          )
        : null;

      const listingSubCategories = listingConfig[listingSubCategoriesKey];
      const listingAttributes = extendCategoriesAttributes(
        listingConfig?.listingAttributesFields ?? [],
        values.attributes
      );

      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDetailsForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const categoryRequiredMsg = intl.formatMessage({ id: 'EditListingDetailsForm.categoryRequired' });

      // Show title and description only after listing type is selected
      const showTitle = listingType;
      const showDescription = listingType;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const handleOnChange = (formValues) => {
        if (masterCategory !== formValues.values.masterCategory && values.subCategory) {
          form.change("subCategory", undefined);
        }

        if (formValues.values?.masterCategory?.toLowerCase() !== OTHER && values.masterCategoryOther) {
          form.change("masterCategoryOther", undefined);
        }
        if (formValues.values?.subCategory?.toLowerCase() !== OTHER && values.subCategoryOther) {
          form.change("subCategoryOther", undefined);
        }

        const valuesToClear = [];
        const keys = Object.keys(formValues.values);

        keys.forEach(key => {
          if (
            key.indexOf("FreeInput") > 0 &&
            formValues.values[key.replace("FreeInput", "")] !== OTHER_FREE_INPUT
          ) {
            valuesToClear.push(key);
          }
        });

        form.batch(() => {
          valuesToClear.forEach((v) => {
            form.change(v, undefined);
          });
        })
      }

      const price = new Money(0, currency);
      const formattedPrice = formatMoney(intl, price);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FormSpy onChange={handleOnChange} subscription={{ values: true }} />
          <ErrorMessage fetchErrors={fetchErrors} />

          <FieldSelectListingType
            name="listingType"
            listingTypes={selectableListingTypes}
            hasExistingListingType={hasExistingListingType}
            onListingTypeChange={onListingTypeChange}
            formApi={formApi}
            intl={intl}
          />

          {showTitle ? (
            <FieldTextInput
              id={`${formId}title`}
              name="title"
              className={css.title}
              type="text"
              label={intl.formatMessage({ id: 'EditListingDetailsForm.titleField' })}
              placeholder={intl.formatMessage({ id: 'EditListingDetailsForm.titleFieldPlaceholder' })}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              autoFocus={autoFocus}
            />
          ) : null}

          {showDescription ? (
            <FieldTextInput
              id={`${formId}description`}
              name="description"
              className={css.description}
              type="textarea"
              label={intl.formatMessage({ id: 'EditListingDetailsForm.descriptionField' })}
              placeholder={intl.formatMessage({
                id: 'EditListingDetailsForm.descriptionFieldPlaceholder',
              })}
              validate={required(
                intl.formatMessage({
                  id: 'EditListingDetailsForm.descriptionRequired',
                })
              )}
            />
          ) : null}

          {listingType ? <FieldSelect
            id={`${formId}.masterCategory`}
            className={css.selectField}
            name="masterCategory"
            label={intl.formatMessage({ id: 'EditListingDetailsForm.masterCategoryLabel' })}
            validate={required(categoryRequiredMsg)}
          >
            <option disabled value="">
              {intl.formatMessage({ id: 'EditListingDetailsForm.masterCategoryPlaceholder' })}
            </option>
            {listingMasterCategories.map(({label, value}) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </FieldSelect> : null}

          {values?.masterCategory?.toLowerCase() === OTHER
            ? (
              <FieldTextInput
                id={`${formId}masterCategoryOther`}
                name="masterCategoryOther"
                className={css.title}
                type="text"
                label={intl.formatMessage({ id: 'EditListingDetailsForm.masterCategoryLabelOther' })}
                placeholder={intl.formatMessage({ id: 'EditListingDetailsForm.masterCategoryPlaceholderOther' })}
                validate={required(categoryRequiredMsg)}
              />
            ) : null}

            <ListingSubCategoriesFields
              subCategories={listingSubCategories}
              attributes={listingAttributes}
              intl={intl}
              formId={formId}
              values={values}
            />

          {isHire &&
           !excludePerLiterCharge.includes(masterCategory?.toLowerCase()) &&
           isActivePerLiterCharge ? (
              <FieldTextInput
                id={`${formId}perLiterCharge`}
                format={formatInputValue}
                parse={formatInputValue}
                name="perLiterCharge"
                className={css.title}
                type="text"
                label={intl.formatMessage({ id: 'EditListingDetailsForm.perLiterChargeLabel' })}
                placeholder={intl.formatMessage(
                  { id: 'EditListingDetailsForm.perLiterChargePlaceholder' },
                  { currency: formattedPrice[0] }
                )}
              />
            ) : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDetailsFormComponent.defaultProps = {
  className: null,
  formId: 'EditListingDetailsForm',
  fetchErrors: null,
  hasExistingListingType: false,
  listingFieldsConfig: [],
};

EditListingDetailsFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onListingTypeChange: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  selectableListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionProcessAlias: string.isRequired,
      unitType: string.isRequired,
    })
  ).isRequired,
  hasExistingListingType: bool,
  listingFieldsConfig: propTypes.listingFieldsConfig,
};

export default compose(injectIntl)(EditListingDetailsFormComponent);
